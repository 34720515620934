@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip {
  @apply z-[100] p-3 pr-4 text-sm max-w-md pointer-events-none antialiased font-light text-grayDark-1200 bg-grayDark-700 border border-grayDark-1100 rounded-md;
}

.stocks-list-table.table {
  @apply bg-gray-100;
}

.stocks-list-table.table tr {
  @apply border-b;
}

.stocks-list-table tr.current-active-row {
  @apply text-white bg-teal-800;
}

.stocks-list-table tr.current-active-row:hover td {
  @apply bg-teal-800;
}

.centered-container {
  @apply mx-auto w-full max-w-screen-2xl px-6 sm:px-14;
}

/* To reuse .centered-container className for breakpoints, we need to define explicitly how the className md:centered-container should work */
@media (min-width: 768px) {
  .md\:centered-container {
    @apply mx-auto w-full max-w-screen-2xl sm:px-14 px-0;
  }
}

.long-dashes {
  background-image: url("data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' rx='6' ry='6' stroke='%23001A0027' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 6px;
}

.long-dashes-danger {
  background-image: url("data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' rx='6' ry='6' stroke='%23E54D2E' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 6px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@keyframes sidePanelIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes sidePanelOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.SidePanelDialogContent[data-state="open"] {
  animation: sidePanelIn 400ms ease-out;
}

.SidePanelDialogContent[data-state="closed"] {
  animation: sidePanelOut 400ms ease-in;
}
